<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>{{ $t('product') }} - {{ $t('loginPageTitle') }}</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              
              <v-row v-if="csrfError">
                <v-col
                  cols="12"
                >
                  <v-alert
                    icon="mdi-cloud-off-outline"
                    type="error"
                    dense
                    style="margin:10px;"
                  >
                    {{ $t('generalErrorCloud') }}
                  </v-alert>
                </v-col>
              </v-row>

              <v-row v-if="loginError">
                <v-col
                  cols="12"
                >
                  <v-alert
                    type="error"
                    dense
                    style="margin:10px;"
                  > 
                    {{ $t('loginPageInvalidCredentials') }}
                  </v-alert>
                </v-col>
              </v-row>
              
              <v-row v-if="isNewAccount">
                <v-col
                  cols="12"
                >
                  <v-alert
                    type="success"
                    dense
                    style="margin:10px;"
                  >
                    {{ $t('loginPageNewAccount') }}
                  </v-alert>
                </v-col>
              </v-row>

                            
              <v-row v-if="isNewPassword">
                <v-col
                  cols="12"
                >
                  <v-alert
                    type="success"
                    dense
                    style="margin:10px;"
                  >
                    {{ $t('loginPageNewPasswort') }}
                  </v-alert>
                </v-col>
              </v-row>

              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="username"
                    :label="$t('loginPageUsername')"
                    :rules="[rules.required, rules.email]"
                    name="login"
                    prepend-icon="mdi-account"
                    type="text"
                    tabindex="1"
                    @keyup="disableErrorMsg"
                  />

                  <v-text-field
                    v-model="password"
                    :label="$t('loginPagePassword')"
                    :rules="[rules.required]"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    tabindex="2"
                    v-on:keyup.enter="sendLoginData"
                    @keyup="disableErrorMsg"
                  />

                  <input
                    v-model="csrf"
                    type="hidden"
                  >
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <a
                  style="margin-right:30px;"
                  href="/password-forgotten"
                  tabindex="4"
                >{{ $t("loginPagePasswordForgotten") }}</a>
                <v-btn
                  :loading="loading"
                  color="primary"
                  style="margin-right:10px; padding-left:20px; padding-right:20px"
                  @click="sendLoginData"
                  tabindex="3"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  // import bcrypt from 'bcryptjs';
  import { createClientHash, validateEmailRegex } from '../../utils/general';

  export default {
    props: {
      source: String,
    },

    data () {
      return {
        isNewAccount: false,
        isNewPassword: false,
        loginError: false,
        csrfError: false,
        csrf: null,
        username: '',
        password: '',
        loading: false,
        rules: {
          required: value => !!value || this.$t('generalValidationErrorRequired'),
          email: value => {
            return validateEmailRegex().test(value) || this.$t('generalValidationErrorEmail')
          }
        }
      }
    },

    mounted () {
      if (process.env.VUE_APP_DEV_USER)     this.username = process.env.VUE_APP_DEV_USER;
      if (process.env.VUE_APP_DEV_USER_PWD) this.password = process.env.VUE_APP_DEV_USER_PWD;

      this.$axios
        .get('v1/public/csrf_token')
        .then(response => {
          this.csrfError = false;
          this.csrf = response.data.csrfToken;
        })
        .catch(() => {
          this.csrfError = true;
      });
      
      try {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('newAccount') !== null) this.isNewAccount = true;
        else if (urlParams.get('newPassword') !== null) this.isNewPassword = true;
      } catch (err) {
        // todo: log on serverside  
      }
    },
    methods: {
      sendLoginData: async function() {
        this.loading=true;

        try {
          const hash = createClientHash(this.username, this.password);
          await this.$axios.post('/v1/public/login', { username: this.username, hash, _csrf: this.csrf});
          location.href='/';
        } catch(err) {
          this.loginError = true;
        }
        this.loading=false;
      },
      disableErrorMsg: function() {
        this.loginError = false;
      }
    }
  }
</script>
