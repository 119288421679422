import Vue from 'vue'
import Login from './Login.vue'
import '../../registerServiceWorker'
import i18n from '../../i18n'
import vuetify from '../../plugins/vuetify'
import axios from 'axios'

Vue.config.productionTip = false;

let baseURL = `${window.location.protocol}//${window.location.hostname}/tls`;
// if (process.env["VUE_APP_API_PORT"]) baseURL = `${window.location.protocol}//${window.location.hostname}:${process.env["VUE_APP_API_PORT"]}/tls`;

if (process.env["VUE_APP_API_PORT"] && process.env["VUE_APP_API_URL"]) {
  baseURL = `${process.env["VUE_APP_API_URL"]}:${process.env["VUE_APP_API_PORT"]}/tls`;
}

Vue.prototype.$axios = axios.create({
  baseURL,
  timeout: 5000,
  withCredentials: true
  // httpAgent: new http.Agent({ keepAlive: true }),
  // headers: {'X-Custom-Header': 'foobar'}
});

new Vue({
  i18n,
  vuetify,
  render: function (h) { return h(Login) }
}).$mount('#app')
