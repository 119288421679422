import bcrypt from 'bcryptjs';

export function createClientHash (username, password) {

    for (let i=username.length; i<25; i++) username += "0";

    let salt = btoa(username).substr(0,25);
    let hash = bcrypt.hashSync(password, `$2a$12$${salt}`);

    return hash;
}

export function validatePasswordRegex() {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
}

export function validateEmailRegex() {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

export function capitalizeFirstLetter(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}